import { ErrorResponse } from './ownerApiClient';
import { LoginRequiredError } from '../errors/LoginRequiredError';

// APIレスポンスから認証/認可状態のチェックを行い必要に応じてErrorをthrowする
export async function checkAuthError(res: Response): Promise<void> {
  const resForFunc = res.clone();
  let json: ErrorResponse;

  try {
    json = await resForFunc.json();
  } catch (e) {
    // JSONレスポンスでないならチェックの必要なし
    return;
  }

  if (json.code === undefined) {
    return;
  }

  const { code } = json;

  if (code === 'OWNER20001') {
    throw new LoginRequiredError();
  }
}
