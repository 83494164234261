import { Owner } from '@stafes/gochikuru-now-api-client';

interface APIConfig {
  basePath: string;
}

class Configuration {
  private configuration: Owner.ConfigurationParameters;
  constructor() {
    this.configuration = this.setConfigurationParameters({
      basePath: process.env.API_BASE_URL || 'API URL not found',
    });
  }

  public setConfigurationParameters(
    config: APIConfig,
  ): Owner.ConfigurationParameters {
    const getHeaders = () => {
      if (typeof window === 'undefined') {
        return {
          'Content-Type': 'application/json',
          'x-stafes-from': '',
        };
      }
      return {
        'Content-Type': 'application/json',
        'x-stafes-from': location.href,
      };
    };

    return {
      basePath: config.basePath,
      headers: getHeaders(),
      credentials: 'include' as RequestCredentials,
    };
  }

  public getConfigurationParameters() {
    return this.configuration;
  }
}

export default new Owner.Configuration(
  new Configuration().getConfigurationParameters(),
);
