import * as React from 'react';
import styled from 'styled-components';
import { Typography, CompanyLogo } from '@stafes/gochikuru-now-ui';

const FooterContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.spacing(4)}px;
  margin-top: auto;
  border-top: 1px solid ${(props) => props.theme.border.normal};

  @media (max-width: ${(props) => props.theme.breakPoints.small}) {
    flex-direction: column;
  }
`;

const LogoContainer = styled.div`
  margin: 2px 24px 2px 0;

  @media (max-width: ${(props) => props.theme.breakPoints.small}) {
    margin: 10px 0;
  }
`;

const LinkContainer = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.breakPoints.small}) {
    margin: 10px 0;
  }
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
`;

const LinkText: React.FC<{ text: string }> = ({ text, ...props }) => (
  <Typography
    variant="body"
    style={{ fontWeight: 'bold', margin: 'auto 16px' }}
    {...props}
  >
    {text}
  </Typography>
);

const CopyrightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1; /* 何故かこれで動く、たぶんいい方法あるので直したい */

  @media (max-width: 450px) {
    margin: 10px 0;
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <LogoContainer>
        <CompanyLogo />
      </LogoContainer>
      <LinkContainer>
        <Link
          href="https://stafes.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkText text="運営会社" />
        </Link>
        <Link
          href="https://stafes.co.jp/company/policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkText text="個人情報保護方針" />
        </Link>
      </LinkContainer>
      <CopyrightContainer>
        <Typography variant="body">
          Copyright © STAR FESTIVAL.INC. All rights reserved
        </Typography>
      </CopyrightContainer>
    </FooterContainer>
  );
};

export { Footer };
