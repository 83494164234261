import { checkAuthError } from './checkAuthError';
import { Owner } from '@stafes/gochikuru-now-api-client';
import Configuration from '../config/apiClientConfig';
import { BaseAPI } from '@stafes/gochikuru-now-api-client/dist/owner/src';

function genClient<T extends BaseAPI>(client: T): T {
  return client.withPostMiddleware(async ({ response }) => {
    await checkAuthError(response);
    if (response.status >= 400) {
      const errorResponse = response.clone() as Response;
      throw errorResponse;
    }
  });
}

export const getActionsApiClient = () => {
  return genClient(new Owner.ActionsApi(Configuration));
};

export const getCompaniesApiClient = () => {
  return genClient(new Owner.CompaniesApi(Configuration));
};

export const getCompanyBasesApiClient = () => {
  return genClient(new Owner.CompanyBasesApi(Configuration));
};

export const getOwnersApiClient = () => {
  return genClient(new Owner.OwnersApi(Configuration));
};

export const getMemberApiClient = () => {
  return genClient(new Owner.MemberApi(Configuration));
};

export const getSlackApiClient = () => {
  return genClient(new Owner.SlackApi(Configuration));
};

export const getShortUrlApiClient = () => {
  return genClient(new Owner.ShortUrlsApi(Configuration));
};

export interface ErrorResponse {
  code: string;
  message: string[];
}
