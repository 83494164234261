import * as React from 'react';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import { LoadingLayout } from '../Layout/LoadingLayout';
import { routes } from '../../config/routes';

interface Props {
  loading: boolean;
  loadSession: () => void;
  isLoggedIn: boolean;
}

/**
 * ログイン済みの際はログイン済みページへリダイレクトする用のWrapper Component
 */
class NonAuthPageBase extends React.Component<Props & WithRouterProps> {
  componentDidMount(): void {
    if (this.props.loading) {
      this.props.loadSession();
    }
  }

  render() {
    const { children, loading, isLoggedIn } = this.props;

    if (loading) {
      return <LoadingLayout />;
    }

    if (isLoggedIn) {
      this.props.router.push(routes.workspace);
      return <LoadingLayout />;
    }

    return children;
  }
}

const NonAuthPageBaseWithRouter = withRouter(NonAuthPageBase);

export { NonAuthPageBaseWithRouter as NonAuthPageBase };
