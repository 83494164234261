import { connect } from 'react-redux';
import { State, Dispatch } from '../../store/types';
import { NonAuthPageBase } from '../../components/NonAuthPageBase';
import { loadSession } from '../../store/operations/loadSession';

const mapStateToProps = (state: State) => ({
  loading: !state.session.loaded,
  isLoggedIn: state.session.session.loggedIn,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadSession() {
    dispatch(loadSession());
  },
});

export const NonAuthPageBaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NonAuthPageBase);
