import * as React from 'react';
import { Footer } from '../../Footer';
import styled from 'styled-components';

interface Props {
  noPaddingTop?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div<Props>`
  background-color: ${(props) => props.theme.base.backgroundColor};

  @media (min-width: ${(props) => props.theme.breakPoints.small}) {
    padding-top: ${(props) =>
      props.noPaddingTop ? 0 : props.theme.spacing(20)}px;
  }
`;

// フッターのみのレイアウト
const WithFooterLayout: React.FC<Props> = ({
  children,
  noPaddingTop = false,
}) => {
  return (
    <Wrapper>
      <ContentWrapper noPaddingTop={noPaddingTop}>{children}</ContentWrapper>
      <Footer />
    </Wrapper>
  );
};

export { WithFooterLayout };
