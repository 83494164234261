import { captureException } from '../logger/logger';
import { DEFAULT_ERROR_MESSAGE } from '../resources/messages/error';
import { ErrorResponse } from './ownerApiClient';

export async function extractErrorMessage(
  e: Error | Response,
): Promise<string[]> {
  if (e instanceof Response) {
    try {
      const json = (await e.json()) as ErrorResponse;

      if (Array.isArray(json.message)) {
        return json.message;
      } else {
        return [json.message];
      }
    } catch (e) {
      const err = e as Error;
      captureException(err);
      throw new Error('不明なエラーが発生しました');
    }
  } else {
    return [DEFAULT_ERROR_MESSAGE];
  }
}
