import * as React from 'react';
import { Grid, Loading } from '@stafes/gochikuru-now-ui';
import styled from 'styled-components';

const LayoutGrid = styled(Grid)`
  height: 100vh;
`;

const LoadingLayout: React.FC = () => {
  return (
    <LayoutGrid justifyContent="center" alignItems="center">
      <Loading />
    </LayoutGrid>
  );
};

export { LoadingLayout };
