// Routing管理
export const routes = {
  login: '/login',
  news: '/news',
  setPassword: '/set_password',
  resetPasswordRequest: '/reset_password_request',
  workspace: '/workspace',
  orderHistory: '/order_history',
  orders: '/orders',
  slackConfig: '/config/slack',
  workers: '/workers',
  updatePassword: '/update_password',
  logout: '/logout',
};
