import { LoginRequiredError } from '../../errors/LoginRequiredError';
import { extractErrorMessage } from '../../httpClients/formatter';
import { getMemberApiClient } from '../../httpClients/ownerApiClient';
import { captureException } from '../../logger/logger';
import { session } from '../modules/session';
import { ThunkAction } from '../types';

export const loadSession = (): ThunkAction<void> => async (dispatch) => {
  await dispatch(session.actions.load());

  const MemberApiClient = getMemberApiClient();

  try {
    const res = await MemberApiClient.getMemberMe();
    const { user_id: userId, user_name: name, service_type: serviceType } = res;

    await dispatch(session.actions.loadSuccess({ userId, name, serviceType }));
  } catch (e) {
    if (e instanceof LoginRequiredError) {
      // 未ログインの場合、未ログインセッションとして成功として扱う
      dispatch(session.actions.loadSuccess({}));
      return;
    }

    if (e instanceof Error) {
      captureException(e);
    }

    dispatch(
      session.actions.loadFailed({
        messages: await extractErrorMessage(e as Response),
      }),
    );
  }
};
