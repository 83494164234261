import * as Sentry from '@sentry/node';

export function isSentryEnabled(): boolean {
  return process.env.SENTRY_ENABLED === 'true';
}

export function captureException(
  e: Error,
  tags?: Record<string, any>,
  extra?: Record<string, any>,
  level?: Sentry.Severity,
): void {
  if (!isSentryEnabled()) {
    console.error('captureException called.', {
      e,
      tags,
      extra,
      level,
    });
    return;
  }

  Sentry.withScope((scope) => {
    if (tags !== undefined && Object.keys(tags).length > 0) {
      scope.setTags(tags);
    }
    if (extra !== undefined && Object.keys(extra).length > 0) {
      scope.setExtras(extra);
    }
    if (level !== undefined) {
      scope.setLevel(level);
    }
    Sentry.captureException(e);
  });
}
