import * as React from 'react';
import styled from 'styled-components';
import { ServiceVerticalLogo } from '@stafes/gochikuru-now-ui';

const LogoContainer = styled.div`
  margin: ${(props) => props.theme.spacing(4)}px;
  display: flex;
  justify-content: center;
`;

const FormLogo: React.FC = () => (
  <LogoContainer>
    <ServiceVerticalLogo />
  </LogoContainer>
);

export { FormLogo };
