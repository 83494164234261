import * as React from 'react';
import NextHead from 'next/head';

interface Props {
  title: string;
  description?: string;
  noindex: boolean;
}

const DEFAULT_DESCRIPTION = '当日10時まで注文できる！選べる日替わりメニュー';

/* eslint-disable no-irregular-whitespace */
const Head: React.FC<Props> = ({ title, description, noindex }) => (
  <NextHead>
    <title>{`${title} | ごちクルNow　管理画面`}</title>
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    {noindex && <meta name="robots" content="noindex" />}
    <meta property="og:title" content={title} />
    <meta
      property="og:description"
      content={description || DEFAULT_DESCRIPTION}
    />
    <meta property="og:type" content="blog" />
    <meta property="og:site_name" content={title} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <link rel="shortcut icon" href="/images/favicon.ico" type="image/x-icon" />
  </NextHead>
);

export { Head };
